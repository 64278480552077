import React,{ Suspense ,lazy} from 'react'
import { BrowserRouter as Router, Route,Switch,withRouter } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { routes, RouteWithSubRoutes } from './router'
import {Skeleton} from 'antd';
const App = (props) => {
  const fallback = () => {
    return (
      <div style={{textAlign:'center',height:'100vh'}}>
        <Skeleton active />
        <Skeleton active />
        <Skeleton active />
        <Skeleton active />
        <Skeleton active />
        <Skeleton active />
      </div>
    )
  }

  return (
    <Suspense fallback ={fallback()}>
    {/* 可以是自定义的菊花图等 */}
    <Router>
      <Switch>
        {routes.map((route, i) => (
          <RouteWithSubRoutes key={i} {...route} />
          ))}
      </Switch>
    </Router>
    </Suspense>
  )
}
export default App;