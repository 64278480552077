import React, { Suspense, lazy } from "react";
import { Route, Redirect } from "react-router-dom";

const HomeVip = lazy(() => import("../pages/home-vip")); // 官网首页
const XxshowVip = lazy(() => import("../pages/xxshow-Vip")); // 官网首页
const XxshowImg = lazy(() => import("../pages/xxshow-img")); // 官网首页
const UploadVip = lazy(() => import("../pages/upload-vip")); // 下载中心
const Nfount = lazy(() => import("../pages/nofount")); // 404

export const routes = [
  {
    path: "/",
    name: "HomeVip",
    exact: true,
    component: HomeVip,
    title: "美术宝AR",
  },
  {
    path: "/uploadVip",
    name: "uploadVip",
    title: "美术宝AR",
    component: UploadVip,
    auth: true,
  },
  {
    path: "/xxshowVip",
    name: "XxshowVip",
    title: "美术宝AR",
    component: XxshowVip,
    auth: true,
  },
  {
    path: "/xxshowImg",
    name: "XxshowImg",
    title: "美术宝AR",
    component: XxshowImg,
    auth: true,
  },
  {
    path: "*",
    name: "nofount",
    title: "404",
    component: Nfount,
  },
];
// 路由权限控制注释
export function RouteWithSubRoutes(route) {
  // 登录认证
  // const userName = storage('userName')
  const userName = "";
  document.title = route.title || "AR-world";
  return (
    <Route
      exact={route.exact || false}
      path={route.path}
      // render={props =>
      //     userName && route.path === '/login'
      //     ? <Redirect to={{pathname: '/'}} />
      //     : !route.auth ? <route.component {...props } {...route} />
      //     : userName ? <route.component {...props } {...route} />
      //     :<Redirect to={{pathname: 'Login'}} />
      // }
      render={(props) => <route.component {...props} {...route} />}
    />
  );
}
